/* MainNavMobileMenu styles */
.main-nav-mobile-menu-close-button {
  @apply mb-5 mr-2 flex w-full animate-fade-in justify-end;

  button {
    @apply mr-0;
  }
}

.main-nav-mobile-menu-section {
  @apply mb-10 flex w-full animate-fade-in flex-col text-center last:pb-16;

  .main-nav-mobile-menu-item {
    @apply mb-1 flex justify-center;
  }
}

.main-nav-mobile-menu-inner-container {
  @apply relative flex w-full animate-fade-in flex-col p-3;
}

.main-nav-mobile-menu-container {
  /* skeleton */
  @apply fixed left-0 top-0 z-[1001] flex h-screen w-screen animate-fade-in overflow-y-auto overflow-x-hidden;

  /* color */
  @apply bg-white;
}

:global(.dark) .main-nav-mobile-menu-container {
  @apply bg-gray-zero;
}
