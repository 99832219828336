/* PreviewVideo styles */

.backgroundCanvas {
  /* skeleton */
  @apply absolute -top-0.5 z-0 w-full;

  /* color */
  @apply bg-white;

  /* height */
  @apply h-[120px] sm:h-[180px] md:h-[220px] lg:h-[340px] xl:h-[380px];
}

:global(.dark) .backgroundCanvas {
  /* color */
  @apply bg-gray-zero;
}

.snippetBottomRight {
  /* skeleton */
  @apply absolute bottom-[-100px] right-[-80px] left-auto z-0 hidden h-80 w-80 md:block;

  /* background */
  @apply bg-transparent bg-snippet-tacao-1 bg-contain bg-no-repeat;
}

.snippetBottomLeft {
  /* skeleton */
  @apply absolute -bottom-16 -left-20 top-auto hidden h-52 w-52 md:block;

  /* bg image */
  @apply bg-transparent bg-scribble-desertstorm-1 bg-contain bg-no-repeat;
}

.snippetTop {
  /* skeleton */
  @apply absolute -top-32 -right-36 left-auto hidden h-52 w-52 lg:block;

  /* background */
  @apply bg-transparent bg-scribble-tuatara-1 bg-contain bg-no-repeat;
}

:global(.dark) .snippetTop {
  @apply bg-scribble-desertstorm-2;
}

.imageButtonCanvas {
  /* skeleton */
  @apply absolute z-10 m-auto h-20 w-20 rounded-full sm:h-40 sm:w-40;

  /* interaction */
  @apply transition-all ease-linear;

  /* color */
  @apply bg-gray-zero shadow-default;

  /* after */
  @apply after:relative after:top-5 after:left-1 after:inline-block after:border-r-0 after:border-t-[20px] after:border-b-[20px] after:border-l-[20px] after:border-solid after:border-t-transparent after:border-r-transparent after:border-b-transparent after:border-l-white after:content-[''] after:sm:left-2.5 after:sm:top-10 after:sm:border-l-[60px] after:sm:border-b-[40px] after:sm:border-t-[40px] after:sm:border-r-0;
}

:global(.dark) .imageButtonCanvas {
  @apply bg-gray-zero shadow-default-dark;

  /* after */
  @apply after:border-l-white;
}

.imageButton {
  /* skeleton */
  @apply z-20 flex w-full cursor-pointer content-center items-center justify-center rounded-2xl border-none;

  /* interaction */
  @apply outline-none transition-all ease-linear focus:outline-none;

  /* color */
  @apply bg-white shadow-default;

  /* custom */
  &:hover,
  &:focus {
    .imageButtonCanvas {
      @apply scale-[1.2] shadow-hover;
    }
  }
}

:global(.dark) .imageButton {
  @apply bg-black shadow-default-dark;

  /* custom */
  &:hover,
  &:focus {
    .imageButtonCanvas {
      @apply shadow-hover-dark;
    }
  }
}

.imageInnerContainer {
  /* skeleton */
  @apply relative flex justify-center p-4;
}

.imageContainer {
  /* skeleton */
  @apply z-10 my-0 mx-auto w-full lg:max-w-screen-lg xl:max-w-screen-xl;
}

.innerContainer {
  /* skeleton */
  @apply relative flex w-full flex-col content-center items-center justify-center pb-4 lg:pt-8 lg:pb-16;

  /* color */
  @apply bg-black;
}

:global(.dark) .innerContainer {
  @apply bg-black;
}

.container {
  /* skeleton */
  @apply -mb-40 -mt-40 w-full overflow-hidden pt-40 pb-40;
}
