/* MainNav styles */
.main-nav-logo-container {
  @apply mr-4 flex h-full w-24 pt-2.5 pb-1.5 lg:w-28;
}

.main-nav-menu-mobile-buttons {
  @apply ml-auto -mr-2 flex lg:hidden;

  div {
    &:last-of-type {
      button {
        @apply last:mr-0;
      }
    }
  }
}

.main-nav-menu {
  @apply hidden content-center items-center justify-start lg:flex;

  &:nth-of-type(3) {
    @apply ml-auto justify-end;
  }

  .main-nav-menu-item {
    &:last-child {
      a {
        @apply mr-0;
      }
    }
  }
}

.main-nav-container {
  @apply z-50 flex h-16 w-full justify-center;
}

.main-nav-inner-container {
  @apply flex w-full max-w-full content-center items-center justify-center px-3 xl:max-w-screen-xl 2xl:max-w-screen-2xl;
}
