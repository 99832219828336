/* PostCard */

.tag {
}

.tagsContainer {
  @apply w-full px-2;
}

.nameContainer {
  @apply px-3 pt-4 pb-3;

  h3 {
    @apply text-2xl;
  }
}

.imageContainer {
  @apply h-full w-full;

  source,
  picture,
  img {
    @apply rounded-2xl;
  }
}

.heading {
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.card {
  /* skeleton */
  @apply flex w-full flex-col content-center items-center justify-center rounded-3xl px-2 pt-2 pb-4 md:px-4 md:pt-4 md:pb-6;

  /* interaction */
  @apply transition-all ease-linear;

  /* color */
  @apply bg-transparent hover:shadow-hover;
}

:global(.dark) .card {
  @apply bg-transparent hover:shadow-hover-dark;
}
