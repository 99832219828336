/* MainLayout styles */

.container {
  /* skeleton */
  @apply flex min-h-[100vh] w-full flex-col items-stretch;

  /* color */
  @apply bg-white;
}

:global(.dark) .container {
  @apply bg-gray-zero;
}

.header {
  /* skeleton */
  @apply fixed top-0 left-0 right-0 z-20 w-full transition-all ease-linear;

  /* color */
  @apply bg-transparent shadow-none;
}

:global(.dark) .header {
  @apply bg-transparent shadow-none;
}

.headerIsScrolling {
  @apply shadow-default bg-white;
}

:global(.dark) .headerIsScrolling {
  @apply bg-gray-zero shadow-default-dark;
}

.main {
  /* skeleton */
  @apply flex h-full w-full flex-1 flex-col content-center items-center justify-center;
}

.footer {
  /* skeleton */
  @apply h-auto w-full;
}
