/* GenreCard styles */

.cardNameContainer {
  @apply px-3 pt-4 pb-3;
}

.cardImage {
  @apply h-full w-full;
}

.cardImageContainer {
  @apply h-full w-full;
}

.card {
  /* skeleton */
  @apply flex w-full flex-col content-center items-center justify-center rounded-2xl;

  /* color */
  @apply shadow-default bg-white;
}

:global(.dark) .card {
  @apply bg-gray-zero shadow-hybrid-dark;
}

.container {
  /* skeleton */
  @apply p-2;
}
